import { FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";

export class SignUpFormValidator {

    static organizationName(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';

        if (isEmpty) return {errorMessage: 'Field is required'};

        return null;
    }

    static organizationCode(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';

        if (isEmpty) return {errorMessage: 'Field is required'};

        return null;
    }

    static organizationHead(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';

        if (isEmpty) return {errorMessage: 'Field is required'};

        return null;
    }

    static organizationRegistrationAddress(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';

        if (isEmpty) return {errorMessage: 'Field is required'};

        return null;
    }

    static organizationLocationAddress(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';

        if (isEmpty) return {errorMessage: 'Field is required'};

        return null;
    }

    static organizationPhone(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';

        if (isEmpty) return {errorMessage: 'Field is required'};

        return null;
    }

    static organizationEmail(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';
        const isValidCharacters = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/.test(value);

        if (isEmpty) return {errorMessage: 'Field is required'};
        if (!isValidCharacters) return {errorMessage: 'Field is incorrect'};

        return null;
    }

    static timeZone(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';
        const isValidCharacters = /^UTC[+-]([01]\d|2[0-4])(:?[0-5]\d)?/.test(value);

        if (isEmpty) return {errorMessage: 'Field is required'};
        if (!isValidCharacters) return {errorMessage: 'Field is incorrect'};

        return null;
    }

    static lang(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';
        const isValidCharacters = /^[0-9]{1,20}$/.test(value);

        if (isEmpty) return {errorMessage: 'Field is required'};
        if (!isValidCharacters) return {errorMessage: 'Field is incorrect'};

        return null;
    }

    static userLogin(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';
        const isValidCharacters = /^[0-9a-zA-Z_]+$/.test(value);
        const min = 2;
        const max = 100;
        const isMinLengthValid = value ? value.length >= min : false;
        const isMaxLengthValid = value ? value.length <= max : false;

        if (isEmpty) return {errorMessage: 'Field is required'};
        if (!isValidCharacters) return {errorMessage: 'Allowed to enter numbers, lowercase and uppercase letters of the Latin alphabet, as well as the character "_"'};
        if (!isMinLengthValid) return {errorMessage: 'Minimum length of {{min}} characters'};
        if (!isMaxLengthValid) return {errorMessage: 'Maximum length of {{max}} characters'};

        return null;
    }

    static userPassword(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';
        const isRequiredCharacters = /[0-9]/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value);
        const isValidCharacters = /^[0-9a-zA-Z_]+$/.test(value);
        const min = 8;
        const max = 20;
        const isMinLengthValid = value ? value.length >= min : false;
        const isMaxLengthValid = value ? value.length <= max : false;

        if (isEmpty) return {errorMessage: 'Field is required'};
        if (!isRequiredCharacters) return {errorMessage: 'The password should contain numbers, lowercase and uppercase Latin letters'};
        if (!isValidCharacters) return {errorMessage: 'Allowed to enter numbers, lowercase and uppercase letters of the Latin alphabet, as well as the character "_"'};
        if (!isMinLengthValid) return {errorMessage: 'Minimum length of {{min}} characters'};
        if (!isMaxLengthValid) return {errorMessage: 'Maximum length of {{max}} characters'};

        return null;
    }

    static userPasswordConfirm(): ValidatorFn {
        return (group: FormGroup): {[key: string]: any} => {

            if (
                (group.dirty || group.touched) &&
                group.get('userPassword').value !== group.get('userPasswordConfirm').value
            ) {
                return {errorMessage: "Passwords don't equal"}
            }

            return null;
        };
    }

}
