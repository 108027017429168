import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { config } from '@app-root/config';

@Injectable()
export class DealService {

    constructor(private httpClient: HttpClient) { }

    get(
        requestParams: {
            id: number,
            fields?: any[],
            joins?: any[]
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/deal/get', requestData);
    }

    getList(
        requestParams?: {
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/deal/list', requestData);
    }

}
