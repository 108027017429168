import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CompanySignInComponent } from './company-sign-in';
import { CompanySignUpComponent } from './company-sign-up';
import { CompanyForgotComponent } from './company-forgot';
import { CompanyAuthComponent } from './company-auth.component';

@NgModule({
  imports: [
      CommonModule,
      ReactiveFormsModule,
      RouterModule,
      TranslateModule
  ],
  declarations: [
      CompanyAuthComponent,
      CompanySignInComponent,
      CompanySignUpComponent,
      CompanyForgotComponent
  ],
  exports: [CompanyAuthComponent]
})
export class CompanyAuthModule { }
