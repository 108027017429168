import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs/Observable';

import { LoanTemplateService } from './loan-template.service';
import { SessionService } from '@app-root/services/session.service';

import { IUser } from '@app-root/interfaces';

@Injectable()
export class LoanTemplateRequisitesResolver implements Resolve<any[]> {

    appUser: IUser;

    constructor(
        private loanTemplateService: LoanTemplateService,
        private sessionService: SessionService
    ) {
        this.appUser = this.sessionService.get('user');
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
        return this.loanTemplateService.getRequisites({lang: this.appUser.lang.label});
    }

}
