import {Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {
    LocaleService,
    LocationService,
    SessionService,
    CompanyAuthService,
    BorrowerAuthService
} from '@app-root/services';

import { Router } from '@angular/router';

import { config } from '@app-root/config';


@Component({
  selector: 'public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.css']
})
export class PublicHeaderComponent implements OnInit {

    @Input() appUser: any;

    language: string;
    accountType: number;
    languages: object;
    translate: object = {};
    objectKeys = Object.keys;

    // Listener event click
    @HostListener('document:click', ['$event'])
    onClick(event) {
        if (document.querySelector('#main_header .drop-active')) {
            document.querySelector('#main_header .drop-active').classList.remove('drop-active');
        }
    }

    constructor(
        private translateService: TranslateService,
        private sessionService: SessionService,
        private locationService: LocationService,
        private localeService: LocaleService,
        private companyAuthService: CompanyAuthService,
        private borrowerAuthService: BorrowerAuthService,
        private renderer: Renderer2,
        private domSanitizer: DomSanitizer,
        private router: Router
    ) {
        this.appUser = this.sessionService.get('user');
        this.languages = this.localeService.languages;
    }

    ngOnInit() {
        this.accountType = SessionService.accountType;
        this.language = this.localeService.getCurrentLanguage();
        this.translate = this.translateService.getTranslation(this.language);
    }

    logout(): void {
        this.appUser = null;
        this.accountType = null;
        if (SessionService.accountType === 0) this.companyAuthService.logout();
        else if (SessionService.accountType === 1) this.borrowerAuthService.logout();
    }

    toggleDropDownListNavigation(event): void {

        const elementNav = event.target.closest('.nav-item');
        const heightHeader = document.getElementById('main_header').offsetHeight;

        if (document.querySelector('#main_header .drop-active')) {
            document.querySelector('#main_header .drop-active').classList.remove('drop-active');
        }

        elementNav.querySelector('a.nav-link').classList.toggle('drop-active');

        const dropDownList = elementNav.querySelector('ul.drop-down-list');

        dropDownList.style.top = heightHeader + 'px';
        dropDownList.classList.toggle('drop-active');
    }

    getLanguageName(language: string): string {
        return this.localeService.languages[language];
    }

    switchLanguage(lang: string): void {
        this.language = lang;
        this.sessionService.set('lang', lang);
        this.translateService.use(lang);
        window.location.reload();
    }

    documentationUrl() {
        let url = config.documentationUrl;
        if (this.language === 'ru_RU') url += '/ninja-lender-v2-api-ru/';
        else url += '/ninja-lender-v2-api-en/';
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }

}
