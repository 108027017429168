import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { config } from '@app-root/config';

import { ILoan } from '@app-root/interfaces';

@Injectable()
export class LoanService {

    loanStages: string[] = [
        'draft',
        'awaiting confirmation',
        'in progress',
        'withdrawn',
        'sold',
        'loan issuance',
        'declined by borrower'
    ];

    borrowerLoanStatuses: string[] = ['', 'not confirmed', 'confirmed', 'declined'];
    borrowerLoanStatusColors: string[] = ['', 'color-orange', 'color-green', 'color-red'];

    constructor(private httpClient: HttpClient) { }

    get(
        requestParams: {
            id: number,
            fields?: any[],
            joins?: any[]
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/loan/get', requestData);
    }

    getList(
        requestParams?: {
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/loan/list', requestData);
    }

    getTypeList(
        requestParams?: {
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/loan-type/list', requestData);
    }

    getRefuseReasonList(
        requestParams?: {
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/refuse-reason/list', requestData);
    }

    getLoanPurposeList(
        requestParams?: {
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/loan-purpose/list', requestData);
    }

    getBorrowerLoans(
        requestParams?: {
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/borrower/loan/list', requestData);
    }

    getBorrowerLoan(
        requestParams: {
            id: number,
            fields?: any[],
            joins?: any[]
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/borrower/loan/get', requestData);
    }

    confirmBorrowerLoan(
        requestParams: {
            id: number,
            term?: number,
            amount?: number,
            fields?: any[],
            joins?: any[]
        },
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/borrower/loan/confirm', requestData);
    }

    declineBorrowerLoan(
        requestParams: {
            id: number
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/borrower/loan/decline', requestData);
    }

    checkBorrowerLoan(
        requestParams: {
            id: number
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/borrower/loan/check', requestData);
    }
}
