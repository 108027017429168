import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { Md5 } from 'md5-typescript';

import { JwtService } from '../jwt.service';
import { SessionService } from '../session.service';

import { IBorrower } from '@app-root/interfaces';
import { config } from '@app-root/config';


@Injectable()
export class BorrowerAuthService {

    constructor(
        private httpClient: HttpClient,
        private jwtService: JwtService,
        private sessionService: SessionService,
        private router: Router
    ) { }

    checkAuthentication(): boolean {
        if (!this.jwtService.jwt) return false;

        const jwt = this.jwtService.decode(this.jwtService.jwt);

        if (!jwt || jwt.account_type !== 1) return false;

        return true;
    }

    setAuthenticationData(
        data: {
            access_token: string,
            borrower: {
                id: number,
                pd_consent: boolean,
                pd_consent_date: string
            }
        }
    ): void {
        this.jwtService.jwt = data.access_token;

        const jwtData = this.jwtService.decode(this.jwtService.jwt);

        SessionService.accountType = jwtData.account_type;

        const user: IBorrower = {
            id: jwtData.user_id,
            login: jwtData.login,
            pd_consent: data.borrower.pd_consent,
            pd_consent_date: data.borrower.pd_consent_date,
        };

        this.sessionService.set('user', JSON.stringify(user));
    }

    authentication(
        requestParams: {
            login: string,
            password: string
        }
    ): Observable<any> {

        const requestData: any = {
            interceptorConfig: {authorization: false},
            login: requestParams.login,
            password: Md5.init(requestParams.password)
        };

        return this.httpClient.post(config.rootUrl + '/borrower/auth', requestData);
    }

    authenticationByHash(
        requestParams: {
            hash: string
        }
    ): Observable<any> {

        const requestData: any = {
            interceptorConfig: {authorization: false},
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/borrower/auth-by-hash', requestData);
    }

    logout() {
        this.jwtService.remove();
        this.sessionService.removeItem('lang');
        this.sessionService.removeItem('user');
        this.router.navigateByUrl('/auth/borrower/sign-in');
    }

}
