import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { JwtService, SessionService, UserService, CompanyAuthService } from '@app-root/services';

import { SignInFormValidator } from './form-validators';

@Component({
    templateUrl: './company-sign-in.component.html',
    styleUrls: ['./company-sign-in.component.css']
})
export class CompanySignInComponent implements OnInit {

    translate: object = {};
    signInForm: FormGroup;
    disabledSignInForm: boolean = false;
    messageSignInForm = {
        type: 'info',
        message: ''
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private translateService: TranslateService,
        private companyAuthService: CompanyAuthService,
        private jwtService: JwtService,
        private sessionService: SessionService,
        private userService: UserService,
        private router: Router
    ) {}

    ngOnInit() {

        // Get data
        this.activatedRoute.data.subscribe(data => {
            this.translate = data['translate'];

            this.initSignInForm();
        });

    }

    initSignInForm() {
        this.signInForm = this.formBuilder.group({
            login: ['', [
                SignInFormValidator.login
            ]],
            password: ['', [
                SignInFormValidator.password
            ]]
        });
    }

    onSubmit() {
        const controls = this.signInForm.controls;

        // Checking the form for validity
        if (this.signInForm.invalid) {
            // If the form is not valid, then mark all controls as touched
            Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());

            return;
        }

        this.messageSignInForm = {
            type: 'info',
            message: ''
        };
        this.disabledSignInForm = true;

        const requestParams: any = {
            login: this.signInForm.value.login,
            password: this.signInForm.value.password
        };

        this.companyAuthService.authentication(requestParams).subscribe((response: any) => {

            response = response[0];

            if (response.code === 0) {
                this.jwtService.jwt = response.data.access_token;

                const headers = { Authorization: 'Bearer ' + this.jwtService.jwt };
                const jwtData = this.jwtService.decode(this.jwtService.jwt);

                SessionService.accountType = jwtData.account_type;

                this.userService.get({id: jwtData.user_id}, {headers: headers}).subscribe((user: any) => {

                    user = user[0];

                    if (user.code === 0) {
                        this.sessionService.set('user', JSON.stringify(user.data));
                        if (user.data.lang.label) {
                            this.sessionService.set('lang', user.data.lang.label);
                            this.translateService.use(user.data.lang.label).subscribe(() => {
                                this.router.navigate(['/company/faq']);
                            });
                        } else {
                            this.router.navigate(['/company/faq']);
                        }

                        // window.location.href = '/company/faq';
                    } else {
                        this.messageSignInForm.type = 'danger';
                        this.messageSignInForm.message = user.message;
                    }

                    this.disabledSignInForm = false;

                }, (error: HttpErrorResponse) => {
                    this.messageSignInForm.type = 'danger';
                    this.messageSignInForm.message = this.translate['User not found'];
                    this.disabledSignInForm = false;
                });

            } else {
                this.messageSignInForm.type = 'danger';
                this.messageSignInForm.message = response.message;
            }

            this.disabledSignInForm = false;

        }, (error: HttpErrorResponse) => {
            this.messageSignInForm.type = 'danger';
            this.messageSignInForm = this.translate['Server is temporarily not available'];
            this.disabledSignInForm = false;
        });
    }

}
