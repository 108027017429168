import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { CompanyAuthService } from '@app-root/services';

import { CompanyForgotFormValidator } from './form-validators';


@Component({
  selector: 'app-forgot',
  templateUrl: './company-forgot.component.html',
  styleUrls: ['./company-forgot.component.css']
})
export class CompanyForgotComponent implements OnInit {

    translate: object = {};
    companyForgotForm: FormGroup;
    messageCompanyForgotForm = {
        type: 'info',
        message: ''
    };
    disabledCompanyForgotForm: boolean = false;
    step: number = 1;

    constructor(
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private companyAuthService: CompanyAuthService
    ) {}

    ngOnInit() {

        // Get data
        this.activatedRoute.data.subscribe(data => {

            this.translate = data['translate'];

            this.initCompanyForgotForm();
        });

    }

    initCompanyForgotForm(){
        this.companyForgotForm = this.formBuilder.group({
            login: ['', [
                CompanyForgotFormValidator.login
            ]],
            code: ['', [
                CompanyForgotFormValidator.code(this.step)
            ]]
        });
    }

    submitCompanyForgotForm() {
        const controls = this.companyForgotForm.controls;

        // Checking the form for validity
        if (this.companyForgotForm.invalid) {
            // If the form is not valid, then mark all controls as touched
            Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());

            return;
        }

        this.messageCompanyForgotForm = {
            type: 'info',
            message: ''
        };
        this.disabledCompanyForgotForm = true;

        if (this.step === 1) {

            const requestParams: any = {
                login: this.companyForgotForm.value.login,
            };

            this.companyAuthService.sendVerificationCodeForgot(requestParams).subscribe((response: any) => {

                response = response[0];

                if (response.code === 0) {
                    this.step = 2;
                    this.messageCompanyForgotForm.type = 'info';
                } else {
                    this.messageCompanyForgotForm.type = 'danger';
                }

                this.messageCompanyForgotForm.message = response.message;
                this.disabledCompanyForgotForm = false;

            }, (error: HttpErrorResponse) => {
                this.messageCompanyForgotForm.type = 'danger';
                this.messageCompanyForgotForm.message = this.translate['Server is temporarily not available'];
                this.disabledCompanyForgotForm = false;
            });

        } else if (this.step === 2) {

            const requestParams: any = {
                login: this.companyForgotForm.value.login,
                code: this.companyForgotForm.value.code,
            };

            this.companyAuthService.sendNewPasswordForgot(requestParams).subscribe((response: any) => {

                response = response[0];

                if (response.code === 0) {
                    this.messageCompanyForgotForm.type = 'info';
                } else {
                    this.messageCompanyForgotForm.type = 'danger';
                }

                this.messageCompanyForgotForm.message = response.message;
                this.disabledCompanyForgotForm = false;

            }, (error: HttpErrorResponse) => {
                this.messageCompanyForgotForm.type = 'danger';
                this.messageCompanyForgotForm.message = this.translate['Server is temporarily not available'];
                this.disabledCompanyForgotForm = false;
            });
        }

    }

}
