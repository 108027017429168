import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LOCATION_INITIALIZED } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import 'rxjs/add/operator/toPromise';

import { LocaleService } from '../services/locale';
import { SessionService } from '../services/session.service';
import { ConfigurationService } from '../services/configuration';
import { BorrowerService } from '../services/borrower';
import { UserService } from '../services/user/user.service';
import { JwtService } from '../services/jwt.service';

import { config } from '@app-root/config';

@Injectable()
export class AppLoadService {

    config = config;

    constructor(
        private httpClient: HttpClient,
        private localeService: LocaleService,
        private translateService: TranslateService,
        private sessionService: SessionService,
        private configurationService: ConfigurationService,
        private borrowerService: BorrowerService,
        private userService: UserService,
        private jwtService: JwtService,
        private injector: Injector
    ) {}

    public initLocation(): Promise<any> {
        return new Promise<any>((resolve: any, reject) => {

            // Set location
            const locationInitialized = this.injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

            locationInitialized.then(() => {

                const language = this.localeService.getCurrentLanguage();

                this.translateService.setDefaultLang(this.localeService.defaultLanguage);

                this.translateService.use(language).subscribe(() => {
                    console.info(`Successfully initialized '${language}' language.'`);
                }, err => {
                    console.error(`Problem with '${language}' language initialization.'`);
                }, () => {
                    resolve(null);
                });

            });

        });
    }

    public initConfiguration(): Promise<any> {

        return this.configurationService.getBaseParams()
            .toPromise()
            .then(appConfiguration => {

            if (appConfiguration[0] && appConfiguration[0].code === 0) {
                this.sessionService.set('appConfiguration', JSON.stringify(appConfiguration[0].data));
            }

            return appConfiguration;
        });
    }

    public initSession(): void {

        if (this.jwtService.jwt) {
            const jwt = this.jwtService.decode(this.jwtService.jwt);

            SessionService.accountType = jwt.account_type;
        }

    }

    public initCurrentUser(): Promise<any>|void {

        if (SessionService.accountType === 0) {

            return this.userService.getCurrentUser()
                .toPromise()
                .then(response => {

                    if (response[0] && response[0].code === 0) {
                        this.sessionService.set('user', JSON.stringify(response[0].data));
                    }

                });

        } else if (SessionService.accountType === 1) {

            return this.borrowerService.getCurrentBorrower()
                .toPromise()
                .then(response => {

                    if (response[0] && response[0].code === 0) {
                        this.sessionService.set('user', JSON.stringify(response[0].data.borrower));
                    }

                });

        }

    }

}
