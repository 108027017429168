import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';

import { SessionService } from '../session.service';

import { config } from 'src/app/config';

@Injectable()
export class LocaleService {

    defaultLanguage = 'en_US';
    languages = {
        en_US: 'English',
        pl_PL: 'Polski',
        ru_RU: 'Русский'
    };

    constructor(
        private httpClient: HttpClient,
        private translateService: TranslateService,
        private sessionService: SessionService
    ) { }

    getUserLanguage(): string {

        let language = this.sessionService.get('lang');

        if (!language) language = this.defaultLanguage;

        return language;
    }

    getCurrentLanguage(): string {

        let language = this.translateService.currentLang;

        if (!language) language = this.sessionService.get('lang');
        if (!language) language = this.defaultLanguage;

        return language;
    }

    getLanguageList(
        fields: any[] = [],
        joins: any[] = [],
        filters: any[] = [],
        orders: any[] = [],
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: {}
        };

        if (fields.length > 0) requestData.params.fields = fields;

        if (joins.length > 0) requestData.params.joins = joins;

        if (filters.length > 0) requestData.params.filters = filters;

        if (orders.length > 0) requestData.params.orders = orders;

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/language/list', requestData);
    }

}
