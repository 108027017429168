import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { config } from '@app-root/config';
import { SessionService } from '@app-root/services/session.service';


@Injectable()
export class StatisticsService {

    constructor(
        private httpClient: HttpClient
    ) { }

    getBaseChart(
        requestParams: {
            start_date: string,
            end_date: string
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/statistic/base-chart', requestData);
    }

}
