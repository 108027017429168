import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { config } from '@app-root/config';


@Injectable()
export class BorrowerService {

    constructor(
        private httpClient: HttpClient,
    ) { }

    changePassword(
        requestParams: {
            old_password: string,
            new_password: string,
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/borrower/password-change', requestData);
    }

    setPermissionProcessingPersonalData(
        requestParams: {
            consent: boolean
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/borrower/set-pd-consent', requestData);
    }

    getCurrentBorrower(): Observable<any> {
        return this.httpClient.post(config.rootUrl + '/borrower/get-current-info', {});
    }

}
