import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs/Observable';

import { LoanService } from './loan.service';

@Injectable()
export class LoanResolver implements Resolve<any[]> {

    constructor(private loanService: LoanService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {

        const id = +route.params['id'];

        return this.loanService.get({id: id});
    }

}
