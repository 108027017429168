import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { BorrowerSignInComponent } from './borrower-sign-in';
import { BorrowerAuthComponent } from './borrower-auth.component';

@NgModule({
  imports: [
      CommonModule,
      ReactiveFormsModule,
      RouterModule,
      TranslateModule
  ],
  declarations: [
      BorrowerAuthComponent,
      BorrowerSignInComponent
  ],
  exports: [BorrowerAuthComponent]
})
export class BorrowerAuthModule { }
