import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './borrower-auth.component.html',
    styleUrls: ['./borrower-auth.component.css']
})
export class BorrowerAuthComponent implements OnInit {

    objectKeys = Object.keys;

    constructor() {}

    ngOnInit() {}

}
