import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    {
        path: 'company',
        loadChildren: () => import('./content/company/company.module').then(m => m.CompanyModule),
        data: {breadcrumb: false}
    },
    {
        path: 'borrower',
        loadChildren: () => import('./content/borrower/borrower.module').then(m => m.BorrowerModule),
        data: { breadcrumb: false }
    },
    {path: '**', redirectTo: 'error-404'}
];

const config: object = {
    onSameUrlNavigation: 'reload',
    paramsInheritanceStrategy: 'always',
    preloadingStrategy: PreloadAllModules
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
