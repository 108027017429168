import { FormControl, ValidationErrors} from '@angular/forms';

export class SignInFormValidator {

    static login(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';
        const isValidCharacters = /^[0-9a-zA-Z_@.]{2,100}$/.test(value);

        if (isEmpty) return {errorMessage: 'Field is required'};
        if (!isValidCharacters) return {errorMessage: 'Field is incorrect'};

        return null;
    }

    static password(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';
        const isValidCharacters = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z_]{8,20}$/.test(value);

        if (isEmpty) return {errorMessage: 'Field is required'};
        if (!isValidCharacters) return {errorMessage: 'Field is incorrect'};

        return null;
    }

}
