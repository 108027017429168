import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs/Observable';

import { OrganizationService } from './organization.service';

@Injectable()
export class BorrowerOrganizationsResolver implements Resolve<any[]> {

    constructor(private organizationService: OrganizationService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {

        return this.organizationService.getBorrowerOrganizations();
    }

}
