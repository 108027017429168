import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CompanyAuthModule } from './company-auth';
import { BorrowerAuthModule } from './borrower-auth';

import { AuthComponent } from './auth.component';

@NgModule({
  imports: [
      CommonModule,
      ReactiveFormsModule,
      RouterModule,
      TranslateModule,
      CompanyAuthModule,
      BorrowerAuthModule
  ],
  declarations: [
      AuthComponent
  ],
  exports: [
      AuthComponent
  ]
})
export class AuthModule { }
