import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs/Observable';

import { OrganizationService } from './organization.service';

import {IUser} from '@app-root/interfaces';

@Injectable()
export class OrganizationsOfSoldLoansResolver implements Resolve<any[]> {

    appUser: IUser;

    constructor(private organizationService: OrganizationService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {

        return this.organizationService.getOrganizationsOfSoldLoans();
    }

}
