import { Component, OnInit } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    Validators
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { LocationService, CompanyAuthService, DatetimeService } from '@app-root/services';

import { SignUpFormValidator } from './form-validators';
import { config } from '@app-root/config';

@Component({
  templateUrl: './company-sign-up.component.html',
  styleUrls: ['./company-sign-up.component.css']
})
export class CompanySignUpComponent implements OnInit {

    translate: object = {};
    signUpForm: FormGroup;
    disabledSignUpForm: boolean = false;
    messageSignUpForm = {
        type: 'info',
        message: ''
    };
    languages: any[];
    timeZones: any[];

    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private companyAuthService: CompanyAuthService,
        private locationService: LocationService,
        private datetimeService: DatetimeService,
        private router: Router
    ) {}

    ngOnInit() {

        // Get data
        this.activatedRoute.data.subscribe(data => {
            this.translate = data['translate'];

            const languages = data['languages'][0];
            if (languages.code === 0) this.languages = languages.data;

            this.timeZones = this.locationService.timeZones;

            this.initSignUpForm();
        });
    }

    initSignUpForm() {
        this.signUpForm = this.formBuilder.group({
            organizationName: ['', [
                SignUpFormValidator.organizationName
            ]],
            organizationCode: ['', [
                SignUpFormValidator.organizationCode
            ]],
            organizationHead: ['', [
                SignUpFormValidator.organizationHead
            ]],
            organizationRegistrationAddress: ['', [
                SignUpFormValidator.organizationRegistrationAddress
            ]],
            organizationLocationAddress: ['', [
                SignUpFormValidator.organizationLocationAddress
            ]],
            organizationPhone: ['', [
                SignUpFormValidator.organizationPhone
            ]],
            organizationEmail: ['', [
                SignUpFormValidator.organizationEmail
            ]],
            lang: [this.languages[0].id, [
                SignUpFormValidator.lang
            ]],
            timeZone: [this.datetimeService.getTimeZone(), [
                SignUpFormValidator.timeZone
            ]],
            userLogin: ['', [
                SignUpFormValidator.userLogin
            ]],
            userPasswords: this.formBuilder.group ({
                userPassword: ['', [
                    SignUpFormValidator.userPassword
                ]],
                userPasswordConfirm: [''],
            }, {validator: SignUpFormValidator.userPasswordConfirm(), requiredTrue: true}),
            isAcceptTerms: [false, [
                Validators.requiredTrue
            ]]
        });
    }

    onSubmit() {
        const controls = this.signUpForm.controls;

        // Checking the form for validity
        if (this.signUpForm.invalid) {
            // If the form is not valid, then mark all controls as touched
            Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());

            return;
        }

        this.messageSignUpForm = {
            type: 'info',
            message: ''
        };

        this.disabledSignUpForm = true;

        const requestParams: any = {
            name: this.signUpForm.value.organizationName,
            organization_code: this.signUpForm.value.organizationCode,
            head: this.signUpForm.value.organizationHead,
            address: this.signUpForm.value.organizationRegistrationAddress,
            location_address: this.signUpForm.value.organizationLocationAddress,
            phone: this.signUpForm.value.organizationPhone,
            email: this.signUpForm.value.organizationEmail,
            user: {
                login: this.signUpForm.value.userLogin,
                password: this.signUpForm.value.userPasswords.userPassword,
                lang: this.signUpForm.value.lang,
                time_zone: this.signUpForm.value.timeZone
            }
        };

        this.companyAuthService.registration(requestParams).subscribe((response: any) => {

            response = response[0];

            if (response.code === 0) {
                this.router.navigate(['/auth/company/sign-in']);
                this.messageSignUpForm.type = 'info';
                this.messageSignUpForm.message = this.translate['Data saved successfully'];
            } else {
                this.messageSignUpForm.type = 'danger';
                this.messageSignUpForm.message = response.message;
            }

            this.disabledSignUpForm = false;

        }, (error: HttpErrorResponse) => {
            this.messageSignUpForm.type = 'danger';
            this.messageSignUpForm.message = this.translate['Server is temporarily not available'];
            this.disabledSignUpForm = false;
        });
    }

    getTermsUrl() {
        return config.rootUrl + '/partner/documents/offer.pdf';
    }

}
