import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AuthModule } from './auth';
import { PublicHeaderModule } from './public-header';
import { FooterModule } from './public-footer';
import { HomeModule } from './home';
import { BreadcrumbModule } from '@app-root/shared';
import { Error404Module } from './error-404';

import { PublicComponent } from './public.component';

import { PublicRoutingModule } from './public-routing.module';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        PublicHeaderModule,
        FooterModule,
        AuthModule,
        HomeModule,
        PublicRoutingModule,
        BreadcrumbModule,
        Error404Module
    ],
    declarations: [
        PublicComponent
    ],
    exports: [
        PublicComponent
    ]
})
export class PublicModule { }
