import {FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class CompanyForgotFormValidator {

    static login(control: FormControl): ValidationErrors {

        const value = control.value;
        const isEmpty = value === '';
        const isValidCharacters = /^[0-9a-zA-Z_]{2,100}$/.test(value);

        if (isEmpty) return {errorMessage: 'Field is required'};
        if (!isValidCharacters) return {errorMessage: 'Field is incorrect'};

        return null;
    }

    static code(step: number): ValidatorFn  {
        return (control: FormControl) => {

            const value = control.value;
            const isEmpty = value === '';
            const isValidCharacters = /^[0-9]{6}$/.test(value);

            if (step != 2) return null;

            if (isEmpty) return {errorMessage: 'Field is required'};
            if (!isValidCharacters) return {errorMessage: 'Field is incorrect'};

            return null;
        }
    }

}
