import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { config } from '@app-root/config';
import { SessionService } from '@app-root/services/session.service';


@Injectable()
export class UserService {

    userStatuses: string[] = ['inactive', 'active'];

    constructor(
        private httpClient: HttpClient,
        private sessionService: SessionService
    ) { }

    get(
        requestParams: {
            id: number,
            fields?: any[],
            joins?: any[]
        },
        options: object = {}
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/user/get', requestData, options);
    }

    update(
        requestParams: {
            login?: string,
            password?: string,
            time_zone?: string,
            lang?: string
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/user/update', requestData);
    }

    getList(
        requestParams?: {
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/user/list', requestData);
    }

    public refreshUserSession(
        requestParams: {
            id: number,
            fields?: any[],
            joins?: any[]
        }
    ): void {

        this.get(requestParams).subscribe((user: any) => {

            user = user[0];

            if (user.code === 0) {
                this.sessionService.set('user', JSON.stringify(user.data));
                if (user.data.lang.label) this.sessionService.set('lang', user.data.lang.label);
            }

        }, (error: HttpErrorResponse) => {
            console.log('HttpErrorResponse', error);
        });
    }

    getCurrentUser(
        requestParams?: {
            fields?: any[]
        },
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        return this.httpClient.post(config.rootUrl + '/partner/user/get-current-info', requestData);
    }

}
