import { NgModule } from '@angular/core';
import { AutocompleteDirective } from '@app-root/directives';

@NgModule({
    declarations: [
        AutocompleteDirective
    ],
    exports: [
        AutocompleteDirective
    ]
})
export class AutocompleteModule {}
