import {Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'ng-table',
    templateUrl: './ng-table.component.html',
    styleUrls: [ './ng-table.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class NgTableComponent implements OnInit {

    @Input('data') ngTableData: any;

    @Output()
    cellClicked: EventEmitter<object> = new EventEmitter<object>();

    @Output()
    tableChanged: EventEmitter<object> = new EventEmitter<object>();

    constructor() {}

    ngOnInit() {

        this.ngTableData.columns = this.ngTableData.columns.map((item) => {
            if (!item.classList) item.classList = [];
            if (item.sorting !== false) item.classList.push('sorting');

            return item;
        });

    }

    renderRow(row: object, path: string) {
        return path.split('.').reduce(function(prev, curr) {
            return prev ? prev[curr] : null
        }, row || self);
    }

    onToggleSort(key) {

        let maxPriority: number = 0;

        if (this.ngTableData.columns[key].sorting === false) return false;

        /*
        // Remove sorting of other columns
        this.ngTableData.columns = this.ngTableData.columns.map((item, index) => {

            if (index !== key && item.sorting !== false) item.sorting = '';

            return item;
        });
        */

        if (this.ngTableData.columns[key].sorting.order != 'desc') {
            this.ngTableData.columns.forEach((column: any) => {
                if (column.sorting && column.sorting.priority > maxPriority) {
                    maxPriority = column.sorting.priority;
                }
            });
        }

        switch (this.ngTableData.columns[key].sorting.order) {
            case 'asc':
                this.ngTableData.columns[key].sorting.order = 'desc';
                this.ngTableData.columns[key].sorting.priority = ++maxPriority;
                break;
            case 'desc':
                this.ngTableData.columns[key].sorting.order = '';
                this.ngTableData.columns[key].sorting.priority = 0;
                break;
            default:
                this.ngTableData.columns[key].sorting.order = 'asc';
                this.ngTableData.columns[key].sorting.priority = ++maxPriority;
                break;
        }

        this.tableChanged.emit(this.ngTableData);
    }

    onClickCell(row: any, column: any): void {
        this.cellClicked.emit({row: row, column: column});
    }

}
