import { Injectable, NgZone } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Injectable()
export class DatetimeService {

    // 365 days
    readonly secondsPerYear = 31536000;

    // 31 days
    readonly secondsPerMonth = 2678400;

    readonly secondsPerDay = 86400;
    readonly secondsPerHour = 3600;
    readonly secondsPerMinute = 60;

    constructor(
        private ngZone: NgZone,
        private datePipe: DatePipe,
        private translateService: TranslateService,
    ) {}

    public format(
        value: string,
        format: string = 'yyyy-MM-dd H:mm:ss',
        timezone: string = 'UTC+00:00'
    ): string {
        return this.datePipe.transform(value, format, timezone);
    }

    public convertTimeToSeconds(value: number, from: 'years'|'months'|'days'|'hours'|'minutes'): number {

        let result;

        switch (from) {
            case 'years': {
                result = value * this.secondsPerYear;
                break;
            }
            case 'months': {
                result = value * this.secondsPerMonth;
                break;
            }
            case 'days': {
                result = value * this.secondsPerDay;
                break;
            }
            case 'hours': {
                result = value * this.secondsPerHour;
                break;
            }
            case 'minutes': {
                result = value * this.secondsPerMinute;
                break;
            }
            default: {
                result = 0;
                break;
            }
        }

        return result;
    }

    public convertSecondsToTime(value: number, time: 'years'|'months'|'days'|'hours'|'minutes'): number {

        let result;

        switch (time) {
            case 'years': {
                result = Math.floor(value / this.secondsPerYear);
                break;
            }
            case 'months': {
                result = Math.floor(value / this.secondsPerMonth);
                break;
            }
            case 'days': {
                result = Math.floor(value / this.secondsPerDay);
                break;
            }
            case 'hours': {
                result = Math.floor(value / this.secondsPerHour);
                break;
            }
            case 'minutes': {
                result = Math.floor(value / this.secondsPerMinute);
                break;
            }
            default: {
                result = 0;
                break;
            }
        }

        return result;
    }

    public convertDateToSeconds(value: number): number {
        const date = new Date(value);

        return date.getTime() / 1000;
    }

    public formatTerm(seconds: number, startDate: string, isTime: boolean = false): string {

        let result = '';

        // get translate
        const _dateTime = this.translateService.instant('_dateTime');

        const fromDate = new moment(startDate);
        const toDate = new moment(startDate).add(seconds, 'seconds');

        const countYears = toDate.diff(fromDate, 'years');
        if (countYears > 0) {
            result += countYears + ' ' + _dateTime.countYears[countYears];
            fromDate.add(countYears, 'years');
        }

        const countMonths = toDate.diff(fromDate, 'months');
        if (countMonths > 0) {
            if (result !== '') result += ' ';
            result += countMonths + ' ' + _dateTime.countMonths[countMonths];
            fromDate.add(countMonths, 'months');
        }

        const countDays = toDate.diff(fromDate, 'days');
        if (countDays > 0) {
            if (result !== '') result += ' ';
            result += countDays + ' ' + _dateTime.countDays[countDays];
            fromDate.add(countDays, 'days');
        }

        if (!isTime) return result;

        const countHours = toDate.diff(fromDate, 'hours');
        if (countHours > 0) {
            if (result !== '') result += ' ';
            result += countHours + ' ' + _dateTime.countHours[countHours];
        }

        return result;
    }

    public getAge(date: string): number {
        const birthday = +new Date(date);

        return ~~((Date.now() - birthday) / (31557600000));
    }

    public getBirthday(age: number): string {
        const date = new Date();
        date.setFullYear(date.getFullYear() - age);

        return this.format(date.toString(), 'yyyy-MM-dd');
    }

    public getTimeZone(): string {
        const offset = new Date().getTimezoneOffset();
        const o = Math.abs(offset);

        return 'UTC' + (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2);
    }

    public getFirstDayOfMonth(format: string = 'yyyy-MM-dd', timezone: string = 'UTC+00:00') {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDayOfMonth = new Date(year, month, 1);
        return this.format(firstDayOfMonth.toString(), format, timezone);
    }

    public getCurrentDate(format: string = 'yyyy-MM-dd', timezone: string = 'UTC+00:00') {
        return this.format(Date.now().toString(), format, timezone);
    }

/*=================================================== Progress time ==================================================*/
    public progressTime(elementId: string, startDate, endDate): void {

        const _this = this;
        const interval: any = {};

        // NgZone.runOutsideAngular() - execute code without detect changes
        this.ngZone.runOutsideAngular(() => {

            interval[elementId] = setInterval(function() {

                if (!document.getElementById(elementId)) clearInterval(interval[elementId]);

                let progress: number;

                const currentDateSeconds = Math.floor(Date.now() / 1000);
                const startDateSeconds = _this.convertDateToSeconds(startDate);
                const endDateSeconds = _this.convertDateToSeconds(endDate);
                const term = Math.max(0, endDateSeconds - startDateSeconds);
                const progressSeconds = Math.max(0, currentDateSeconds - startDateSeconds);

                if (term === 0 || progressSeconds === 0) progress = 100;
                else progress = Math.min(100, Math.floor(progressSeconds / term * 100));

                const timeLeft = Math.max(0, term - progressSeconds);
                const text = _this.timer(timeLeft);

                _this.drawProgress(elementId, progress, text);

                if (progress >= 100) clearInterval(interval[elementId]);

            }, 1000);

        });

    }

    private timer(duration: number): string {

        let result = '';

        if (duration < 0) duration = 0;

        // get translate
        const _dateTime = this.translateService.instant('_dateTime');

        if (duration > this.secondsPerDay) {
            const countDays = Math.floor(duration / this.secondsPerDay);
            result += countDays + ' ' + _dateTime.countDays[countDays] + ' ';
            duration = duration % this.secondsPerDay;
        }

        let hours: any = Math.floor(duration / this.secondsPerHour);
        if (hours < 10) hours = '0' + hours;
        duration = duration % this.secondsPerHour;

        let minutes: any = Math.floor(duration / this.secondsPerMinute);
        if (minutes < 10) minutes = '0' + minutes;
        duration = duration % this.secondsPerMinute;

        let seconds: any = duration;
        if (seconds < 10) seconds = '0' + seconds;

        result += hours + ':' + minutes + ':' + seconds;

        return result;
    }

    private drawProgress(elementId: string, progress: number, text: string = '') {

        const elementDOM = document.getElementById(elementId);

        if (!elementDOM) return false;

        const prefixes = ['', '-o-', '-ms-', '-moz-', '-webkit-'];

        for (const prefix of prefixes) {
            elementDOM.innerText = text;

            elementDOM.style.background = prefix + 'linear-gradient(to right, rgba(255, 197, 99, 1) ' +
                progress + '%, rgba(255, 237, 94, 1) 0)';
        }

    }
/*================================================= End progress time ================================================*/



}
