import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './error-404.component.html',
    styleUrls: ['./error-404.component.css']
})
export class Error404Component implements OnInit {

    constructor() { }

    ngOnInit() {}

}
