import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import { JwtService } from '../jwt.service';
import { SessionService } from '../session.service';

import { config } from '@app-root/config';
import { Md5 } from 'md5-typescript';

@Injectable()
export class CompanyAuthService {

    constructor(
        private httpClient: HttpClient,
        private jwtService: JwtService,
        private sessionService: SessionService,
        private router: Router
    ) { }

    authentication(
        requestParams: {
            login: string,
            password: string
        }
    ): Observable<any> {

        const requestData: any = {
            login: requestParams.login,
            password: Md5.init(requestParams.password)
        };

        return this.httpClient.post(config.rootUrl + '/partner/auth', requestData);
    }

    registration(
        requestParams: {
            name: string,
            organization_code: string,
            head: string,
            address: string,
            location_address: string,
            phone: string,
            user: {
                login: string,
                password: string,
                lang: number,
                time_zone: string
            }
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        requestData.params.user.password = Md5.init(requestData.params.user.password);

        return this.httpClient.post(config.rootUrl + '/partner/organization/create', requestData);
    }

    logout() {
        this.jwtService.remove();
        this.sessionService.removeItem('user');
        this.sessionService.removeItem('lang');
        this.router.navigateByUrl('/auth/company');
    }

    sendVerificationCodeForgot(
        requestParams: {
            login: string
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/forgot-password-code', requestData);
    }

    sendNewPasswordForgot(
        requestParams: {
            login: string,
            code: number
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/forgot-password', requestData);
    }

}
