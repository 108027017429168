import { Component, OnInit } from '@angular/core';
import { SessionService } from '@app-root/services';
import {Router} from '@angular/router';

@Component({
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css']
})
export class PublicComponent implements OnInit {

    appUser: any;

    constructor(
        private sessionService: SessionService,
        private router: Router
    ) {
        this.appUser = this.sessionService.get('user');
    }

    ngOnInit() {
        // Detect a route change
        this.router.events.subscribe((val) => {
            this.appUser = this.sessionService.get('user');
        });

        this.sessionService.watch().subscribe((data: string) => {
            this.appUser = this.sessionService.get('user');
        });
    }

    /*
    getServerSession() {
        this.SessionService.getServerSession().subscribe((response : any) => {
            console.log('response', response);
        }, (err : HttpErrorResponse)=>{
            console.log('error', err);
        });
    }

    setServerSession() {
        this.SessionService.setServerSession().subscribe((response : any) => {
            console.log('response', response);
        }, (err : HttpErrorResponse)=>{
            console.log('error', err);
        });
    }
    */

}
