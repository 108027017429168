import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppInterceptor } from './app.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PublicModule } from '@app-root/content/public';

import {
    JwtService,
    SessionService,
    LocationService,
    LocaleService,
    ConfigurationService,
    AppLoadService,
    BorrowerService,
    BorrowerAuthService,
    CompanyAuthService,
    UserService,
    UserResolver,
    OrganizationService,
    OrganizationResolver,
    BuyerConfigurationResolver,
    BorrowerOrganizationsResolver,
    OrganizationsOfPurchasedLoansResolver,
    OrganizationsOfSoldLoansResolver,
    DatetimeService,
    DealService,
    LoanService,
    LoanResolver,
    LoanTypesResolver,
    LoanPurposesResolver,
    RefuseReasonsResolver,
    BorrowerLoanResolver,
    LoanTemplateService,
    LoanTemplateResolver,
    LoanTemplatesResolver,
    LoanTemplateRequisitePropertiesResolver,
    LoanTemplateRequisitesResolver,
    LanguagesResolver,
    TranslateResolver,
    StatisticsService
} from '@app-root/services';


export function translateHttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

/*=================================================== Preload app ====================================================*/
export function initLocation(appLoadService: AppLoadService) {
    return () => appLoadService.initLocation();
}

export function initConfiguration(appLoadService: AppLoadService) {
    return () => appLoadService.initConfiguration();
}

export function initSession(appLoadService: AppLoadService) {
    return () => appLoadService.initSession();
}

export function initCurrentUser(appLoadService: AppLoadService) {
    return () => appLoadService.initCurrentUser();
}
/*================================================== End Preload app =================================================*/

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateHttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        PublicModule,
        AppRoutingModule
    ],
    providers: [
        DatePipe,
        JwtService,
        SessionService,
        LocationService,
        LocaleService,
        ConfigurationService,
        AppLoadService,
        BorrowerService,
        BorrowerAuthService,
        CompanyAuthService,
        UserService,
        UserResolver,
        OrganizationService,
        OrganizationResolver,
        BuyerConfigurationResolver,
        BorrowerOrganizationsResolver,
        OrganizationsOfPurchasedLoansResolver,
        OrganizationsOfSoldLoansResolver,
        DatetimeService,
        DealService,
        LoanService,
        LoanResolver,
        LoanTypesResolver,
        LoanPurposesResolver,
        RefuseReasonsResolver,
        BorrowerLoanResolver,
        LoanTemplateService,
        LoanTemplateResolver,
        LoanTemplatesResolver,
        LoanTemplateRequisitePropertiesResolver,
        LoanTemplateRequisitesResolver,
        LanguagesResolver,
        TranslateResolver,
        StatisticsService,
        { provide: APP_INITIALIZER, useFactory: initLocation, deps: [AppLoadService], multi: true },
        { provide: APP_INITIALIZER, useFactory: initConfiguration, deps: [AppLoadService], multi: true },
        { provide: APP_INITIALIZER, useFactory: initSession, deps: [AppLoadService], multi: true },
        { provide: APP_INITIALIZER, useFactory: initCurrentUser, deps: [AppLoadService], multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
