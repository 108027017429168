import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { UserService } from './user.service';

import { IUser } from '@app-root/interfaces';

@Injectable()
export class UserResolver implements Resolve<IUser> {

    constructor(private userService: UserService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IUser> {

        const id = +route.parent.params['user_id'];

        return this.userService.get({id: id});
    }

}
