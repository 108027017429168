import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { LocaleService } from './locale.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslateResolver implements Resolve<any[]> {

    constructor(
        private translateService: TranslateService,
        private localeService: LocaleService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {

        const language = this.localeService.getCurrentLanguage();

        return this.translateService.getTranslation(language);
    }

}
