import { Component, OnInit } from '@angular/core';

import { config } from '@app-root/config';

@Component({
    selector: 'public-footer',
    templateUrl: './public-footer.component.html',
    styleUrls: ['./public-footer.component.css']
})
export class PublicFooterComponent implements OnInit {

    config = config;
    currentYear: number;

    constructor() { }

    ngOnInit() {
        this.currentYear = (new Date()).getFullYear();
    }

}
