import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs/Observable';

import { LoanTemplateService } from './loan-template.service';

@Injectable()
export class LoanTemplateResolver implements Resolve<any[]> {

    constructor(private loanTemplateService: LoanTemplateService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {

        const id = +route.params['id'];

        return this.loanTemplateService.get({id: id});
    }

}
