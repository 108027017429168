import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { JwtService, SessionService, UserService, BorrowerAuthService } from '@app-root/services';

import { SignInFormValidator } from './form-validators';

import { IBorrower } from '@app-root/interfaces';

@Component({
    templateUrl: './borrower-sign-in.component.html',
    styleUrls: ['./borrower-sign-in.component.css']
})
export class BorrowerSignInComponent implements OnInit {

    translate: object = {};
    signInForm: FormGroup;
    disabledSignInForm: boolean = false;
    messageSignInForm = {
        type: 'info',
        message: ''
    };
    queryParams: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private borrowerAuthService: BorrowerAuthService,
        private jwtService: JwtService,
        private sessionService: SessionService,
        private router: Router
    ) {}

    ngOnInit() {

        // Get data
        this.activatedRoute.data.subscribe(data => {
            this.translate = data['translate'];

            this.initSignInForm();
        });

        this.activatedRoute.queryParams.subscribe(queryParams => {
            this.queryParams = queryParams;

            // if (this.queryParams.auth_hash) this.authenticationByHash();
        });

    }

    /*
    authenticationByHash() {
        const requestParams: any = {
            hash: this.queryParams.auth_hash
        };

        this.borrowerAuthService.authenticationByHash(requestParams).subscribe((response: any) => {

            response = response[0];

            if (response.code === 0) {
                this.borrowerAuthService.setAuthenticationData(response.data);

                let redirectUrl: string;

                if (this.queryParams.redirect_to) redirectUrl = decodeURIComponent(this.queryParams.redirect_to);
                else redirectUrl = '/borrower';

                this.router.navigateByUrl(redirectUrl);

            } else {
                this.messageSignInForm.type = 'danger';
                this.messageSignInForm.message = response.message;
            }

            this.disabledSignInForm = false;

        }, (error: HttpErrorResponse) => {
            this.messageSignInForm.type = 'danger';
            this.messageSignInForm = this.translate['Server is temporarily not available'];
            this.disabledSignInForm = false;
        });
    }
    */

    initSignInForm() {
        this.signInForm = this.formBuilder.group({
            login: ['', [
                SignInFormValidator.login
            ]],
            password: ['', [
                SignInFormValidator.password
            ]]
        });
    }

    onSubmit() {
        const controls = this.signInForm.controls;

        // Checking the form for validity
        if (this.signInForm.invalid) {
            // If the form is not valid, then mark all controls as touched
            Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());

            return;
        }

        this.messageSignInForm = {
            type: 'info',
            message: ''
        };
        this.disabledSignInForm = true;

        const requestParams: any = {
            login: this.signInForm.value.login,
            password: this.signInForm.value.password
        };

        this.borrowerAuthService.authentication(requestParams).subscribe((response: any) => {

            response = response[0];

            if (response.code === 0) {
                this.borrowerAuthService.setAuthenticationData(response.data);

                /*
                if (this.queryParams.redirect_to) this.router.navigate([this.queryParams.redirect_to]);
                else this.router.navigate(['/borrower']);
                */

                let redirectUrl: string;

                if (this.queryParams.redirect_to) redirectUrl = decodeURIComponent(this.queryParams.redirect_to);
                else redirectUrl = '/borrower';

                this.router.navigateByUrl(redirectUrl);

            } else {
                this.messageSignInForm.type = 'danger';
                this.messageSignInForm.message = response.message;
            }

            this.disabledSignInForm = false;

        }, (error: HttpErrorResponse) => {
            this.messageSignInForm.type = 'danger';
            this.messageSignInForm = this.translate['Server is temporarily not available'];
            this.disabledSignInForm = false;
        });
    }

}
