import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgTableComponent } from './ng-table.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NotSanitizeHtmlPipe } from '@app-root/pipes';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        FormsModule,
        PaginationModule
    ],
    declarations: [
        NgTableComponent,
        NotSanitizeHtmlPipe
    ],
    exports: [
        NgTableComponent
    ]
})
export class NgTableModule {}
