import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PublicComponent } from '@app-root/content/public/public.component';
import { HomeComponent } from '@app-root/content/public/home';
import {
    AuthComponent,
    CompanyAuthComponent,
    CompanySignInComponent,
    CompanySignUpComponent,
    CompanyForgotComponent,
    BorrowerAuthComponent,
    BorrowerSignInComponent
} from '@app-root/content/public/auth';
import { Error404Component } from '@app-root/content/public/error-404';

import {
    LanguagesResolver,
    TranslateResolver,
} from '@app-root/services';

const routes: Routes = [
    {
        path: '',
        component: PublicComponent,
        children: [
            {
                path: 'auth',
                component: AuthComponent,
                children: [
                    {
                        path: 'company',
                        component: CompanyAuthComponent,
                        children: [
                            {
                                path: 'sign-in',
                                component: CompanySignInComponent,
                                resolve: {
                                    translate: TranslateResolver,
                                },
                                pathMatch: 'full',
                                data: { breadcrumb: 'Sign In' }
                            },
                            {
                                path: 'sign-up',
                                component: CompanySignUpComponent,
                                resolve: {
                                    languages: LanguagesResolver,
                                    translate: TranslateResolver
                                },
                                pathMatch: 'full',
                                data: { breadcrumb: 'Sign Up' }
                            },
                            {
                                path: 'forgot',
                                component: CompanyForgotComponent,
                                resolve: {
                                    translate: TranslateResolver
                                },
                                pathMatch: 'full',
                                data: { breadcrumb: 'Forgot'}
                            },
                            { path: '', redirectTo: 'sign-in', pathMatch: 'full'}
                        ],
                        data: { breadcrumb: 'Company' }
                    },
                    {
                        path: 'borrower',
                        component: BorrowerAuthComponent,
                        children: [
                            {
                                path: 'sign-in',
                                component: BorrowerSignInComponent,
                                resolve: {
                                    translate: TranslateResolver
                                },
                                pathMatch: 'full',
                                data: { breadcrumb: 'Sign In' }
                            },
                            { path: '', redirectTo: 'sign-in', pathMatch: 'full'}
                        ],
                        data: { breadcrumb: 'Borrower' }
                    },
                    { path: '', redirectTo: 'company', pathMatch: 'full'},
                ],
                data: { breadcrumb: 'Authorization' }
            },
            { path: 'error-404', component: Error404Component, data: { breadcrumb: false } },
            { path: '', component: HomeComponent, pathMatch: 'full', data: { breadcrumb: false }},
        ],
        data: { breadcrumb: false }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PublicRoutingModule { }
