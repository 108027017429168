import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { PublicFooterComponent } from './public-footer.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [PublicFooterComponent],
    exports: [PublicFooterComponent]
})
export class FooterModule { }
