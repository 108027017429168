export const config = {
    rootUrl: 'https://demo.ninjalender.online',
    documentationUrl: 'https://ninja-lender.gitbook.io',
    version: '2.0',
    privacyPolicyUrl: '/partner/files/view-document/privacy-policy',
    termsOfUseUrl: '/partner/files/view-document/terms-of-use',
    contacts: {
        phone: '+48 536 442 003',
        email: 'info@ninjalender.net',
        facebook: 'https://www.facebook.com/NinjaLender',
        linkedin: 'https://www.linkedin.com/company/ninja-lender/',
        nip: 'NIP 9721303586',
        address: 'Szelągowska 49, 61-626 Poznań'
    }
};
