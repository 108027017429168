import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { Error404Component } from './error-404.component';

@NgModule({
  imports: [
      CommonModule,
      ReactiveFormsModule,
      RouterModule,
      TranslateModule
  ],
  declarations: [
      Error404Component
  ],
  exports: [
      Error404Component
  ]
})
export class Error404Module { }
