import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs/Observable';

import { LoanService } from './loan.service';

import { IUser } from '@app-root/interfaces';

@Injectable()
export class RefuseReasonsResolver implements Resolve<any[]> {

    appUser: IUser;

    constructor(private loanService: LoanService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
        return this.loanService.getRefuseReasonList();
    }

}
