import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { config } from '@app-root/config';

@Injectable()
export class OrganizationService {

    constructor(private httpClient: HttpClient) { }

    get(
        requestParams: {
            id: number,
            fields?: any[],
            joins?: any[]
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/organization/get', requestData);
    }

    update(
        requestParams: {
            id: number,
            name: string,
            organization_code: string,
            head: string,
            address: string,
            phone: string,
            type: number
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/organization/update', requestData);
    }

    getList(
        requestParams?: {
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/organization/list', requestData);
    }

    getOrganizationsOfPurchasedLoans(
        requestParams?: {
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/organization/list-sellers', requestData);
    }

    getOrganizationsOfSoldLoans(
        requestParams?: {
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/organization/list-buyers', requestData);
    }

    getBorrowerOrganizations(
        requestParams?: {
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/borrower/organization/list', requestData);
    }

    getBuyerConfiguration(): Observable<any> {
        return this.httpClient.post(config.rootUrl + '/partner/buyer-condition/get', {});
    }

    setBuyerConfiguration(
        requestParams: {
            purchase_enabled_new: boolean,
            purchase_enabled_repeat: boolean,
            default_price_new: number,
            default_price_repeat: number,
            min_amount: number,
            max_amount: number,
            min_term: number,
            max_term: number,
            template_id: number,
            condition_requisites: any[]
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/buyer-condition/set', requestData);
    }

}
