import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs/Observable';

import { LoanTemplateService } from '@app-root/services/loan-template/loan-template.service';
import { SessionService } from '@app-root/services/session.service';
import { IUser } from '@app-root/interfaces';

@Injectable()
export class LoanTemplatesResolver implements Resolve<any[]> {

    appUser: IUser;

    constructor(
        private loanTemplateService: LoanTemplateService,
        private sessionService: SessionService
    ) {
        this.appUser = this.sessionService.get('user');
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {

        const requestParams = {
            organization: this.appUser.organization.id,
            is_moderated: true
        };

        return this.loanTemplateService.getList(requestParams);
    }

}
