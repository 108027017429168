import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { config } from '@app-root/config';

@Injectable()
export class ConfigurationService {

    constructor(
        private  httpClient: HttpClient
    ) { }

    getBaseParams(
        requestParams?: {
            lang?: string,
        }
    ): Observable<any> {

        const requestData: any = {
            interceptorConfig: {authorization: false},
            params: requestParams ? requestParams : {}
        };

        return this.httpClient.post(config.rootUrl + '/partner/system/base-params', requestData);
    }

}
