import { Injectable } from '@angular/core';

@Injectable()
export class JwtService {

    get jwt(): string {
        return localStorage.getItem('access_token');
    }

    set jwt(item: string) {
        localStorage.setItem('access_token', item);
    }

    public remove(): void {
        localStorage.removeItem('access_token');
    }

    public decode(jwt: string, segment: string = 'payload'): any {

        let result: any;

        try {
            const segments_base64: Array<string> = jwt.split('.');

            if (segments_base64.length !== 3) return false;

            if (segment === 'header') {
                const header_json: string = atob(segments_base64[0]);
                result = JSON.parse(header_json);

            } else if (segment === 'payload') {
                const payload_json: string = atob(segments_base64[1]);
                result = JSON.parse(payload_json);
            }

        } catch (e) {
            return false;
        }

        return result;
    }

}
