import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class SessionService {

    static accountType: number;

    private state = new Subject<string>();

    constructor(private httpClient: HttpClient) {}

    public watch(): Observable<any> {
        return this.state.asObservable();
    }

    public set(key: string, value: string): void {
        localStorage.setItem(key, value);
        this.state.next('changed');
    }

    public get(key: string): any {
        let result = localStorage.getItem(key);

        try {
            result = JSON.parse(result);
        } catch (e) {}

        return result;
    }

    public removeItem(key: string): void {
        localStorage.removeItem(key);
        this.state.next('changed');
    }

    /*
    decode(string: any): any {
        return string.split('\u200c')
            .map((w) => w.split('').map((c) => c === '\u200b' ? 1 : 0).join(''))
            .map((w) => {const n: any = parseInt(w, 2); return String.fromCharCode(n.toString(10)); })
            .join('');
    }
    */

}
