import { Directive, OnInit, ElementRef, HostListener, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appAutocomplete]'
})
export class AutocompleteDirective implements OnInit {

    @Input('appAutocomplete') appAutocomplete: any;

    constructor(
        private elementRef: ElementRef,
        @Self() private ngControl: NgControl
    ) {}

    ngOnInit() {}

    @HostListener('document:click', ['$event'])
    onClick(event) {
        if (this.elementRef.nativeElement !== event.target) {
            this.removeList();
        }
    }

    @HostListener('keyup', ['$event'])
    onKeyUp(event) {
        this.init();
    }

    @HostListener('focus', ['$event'])
    onFocus(event) {
        this.init();
    }

    private init() {
        this.removeList();

        if (this.appAutocomplete.minLength && this.ngControl.value.length < this.appAutocomplete.minLength) {
            return false;
        }

        const data = this.appAutocomplete.data.filter(item => item.match(new RegExp(this.ngControl.value, 'i')) && item !== this.ngControl.value);

        if (data.length === 0) {
            return false;
        }

        this.createList(data);
    }

    private createList(data: any[]): void {

        const ul = document.createElement('ul');
        ul.className = 'autocomplete-list';

        data.forEach((item: string) => {
            const li = document.createElement('li');
            li.className = 'autocomplete-item';
            li.setAttribute('data', item);
            li.innerText = item;

            li.addEventListener('click', (event) => {
                this.onSelectItem(event.target);
                this.removeList();
            }, false);

            ul.appendChild(li);
        });

        this.elementRef.nativeElement.parentNode.appendChild(ul);
    }

    private removeList(): void {
        this.elementRef.nativeElement.parentNode.querySelectorAll('ul.autocomplete-list').forEach((element) => {
            element.remove();
        });
    }

    private onSelectItem(element) {
        const itemData = element.getAttribute('data');
        this.ngControl.control.setValue(itemData);
    }

}
