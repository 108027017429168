import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ng-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

    public visible = false;
    public visibleAnimate = false;

    constructor() {}

    ngOnInit() {}

    public show(): void {
        this.visible = true;
        document.body.style.overflow = 'hidden';
        setTimeout(() => this.visibleAnimate = true, 100);
    }

    public hide(): void {
        this.visibleAnimate = false;
        document.body.style.overflow = 'auto';
        setTimeout(() => this.visible = false, 300);
    }

    public onContainerClicked(event: MouseEvent): void {
        if ((<HTMLElement>event.target).classList.contains('modal')) this.hide();
    }

}
