import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { LocaleService } from './locale.service';

@Injectable()
export class LanguagesResolver implements Resolve<any[]> {

    constructor(private localeService: LocaleService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
        return this.localeService.getLanguageList();
    }

}
