import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';

import { BorrowerAuthService, JwtService, CompanyAuthService, SessionService } from '@app-root/services';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

    isAuthCompany: boolean = false;
    isAuthBorrower: boolean = false;

    constructor(
        private sessionService: SessionService,
        private jwtService: JwtService,
        private companyAuthService: CompanyAuthService,
        private borrowerAuthService: BorrowerAuthService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const headers: any = {'Content-Type': 'application/json'};
        const lang = this.sessionService.get('lang');

        if (lang) headers['Ninja-Lang'] = lang;

        const accessToken = this.jwtService.jwt;

        if (accessToken) {
            const jwt = this.jwtService.decode(this.jwtService.jwt);

            if (jwt && jwt.account_type === 0) this.isAuthCompany = true;
            else if (jwt && jwt.account_type === 1) this.isAuthBorrower = true;

            let authorization: boolean = true;

            if (request.body && request.body.interceptorConfig) {
                authorization = request.body.interceptorConfig.authorization;
                delete request.body.interceptorConfig;
            }

            if (authorization) headers['Authorization'] = 'Bearer ' + accessToken;
        }

        request = request.clone({
            setHeaders: headers,
            withCredentials: true
        });

        return next.handle(request).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {
                        const response = event.body[0] ? event.body[0] : event.body;

                        if (!response) return;

                        if (this.isAuthCompany && [-97, -98].includes(response.code)) this.companyAuthService.logout();
                        if (this.isAuthBorrower && [-97, -98].includes(response.code)) this.borrowerAuthService.logout();
                    }
                },
                err => {
                    if (err instanceof HttpErrorResponse) {
                        console.error('AppInterceptor', err);
                    }
                }
            )
        );

    }

}
