import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './company-auth.component.html',
    styleUrls: ['./company-auth.component.css']
})
export class CompanyAuthComponent implements OnInit {

    constructor() {}

    ngOnInit() {}

}
