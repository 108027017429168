import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/map';

import { IBreadcrumb, IUser } from '@app-root/interfaces';
import { LocationService, SessionService } from '@app-root/services';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: [ './breadcrumb.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit {

    appUser: IUser;
    breadcrumbs: any;

    constructor(
        private sessionService: SessionService,
        private locationService: LocationService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {

        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);

        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .distinctUntilChanged()
            .subscribe((event) => {
                this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
            });

    }

    buildBreadCrumb(
        route: ActivatedRoute,
        url: string = '',
        breadcrumbs: Array<IBreadcrumb> = []
    ): Array<IBreadcrumb> {

        const label = route.routeConfig ? route.routeConfig.data['breadcrumb'] : 'Home';
        const path = route.routeConfig ? route.routeConfig.path : '';
        const nextUrl = url + path + (label || path === '' ? '/' : '');
        const breadcrumb = {
            label: label,
            url: nextUrl
        };

        if (breadcrumb.url.indexOf(':user_id') !== -1) {
            this.appUser = this.sessionService.get('user');
            breadcrumb.url = breadcrumb.url.replace(':user_id', this.appUser.id.toString());
        }

        let newBreadcrumbs: any[];

        if (!label) newBreadcrumbs = breadcrumbs;
        else newBreadcrumbs = [ ...breadcrumbs, breadcrumb ];

        if (route.firstChild) return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);

        return newBreadcrumbs;
    }
}
