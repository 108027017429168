import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { config } from '@app-root/config';

@Injectable()
export class LoanTemplateService {

    constructor(private httpClient: HttpClient) { }

    get(
        requestParams: {
            id: number,
            fields?: any[],
            joins?: any[]
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/template/get', requestData);
    }

    set(
        requestParams: {
            label: string,
            loan_type_id: number,
            body: object,
            description?: string,
            requested_requisites?: any[]
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/template/create', requestData);
    }

    update(
        requestParams: {
            id: number,
            label: string,
            loan_type_id: number,
            body: object,
            description?: string,
            requested_requisites?: any[]
        }
    ): Observable<any> {

        const requestData: any = {
            params: requestParams
        };

        return this.httpClient.post(config.rootUrl + '/partner/template/update', requestData);
    }

    getList(
        requestParams?: {
            loan_type?: number,
            organization?: number,
            is_moderated?: boolean,
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/template/list', requestData);
    }

    getRequisites(
        requestParams?: {
            loan_type?: number,
            organization?: number,
            lang?: string,
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/requisite/get-all', requestData);
    }

    getRequisiteProperties(
        requestParams?: {
            fields?: any[],
            joins?: any[],
            filters?: any[],
            orders?: any[]
        },
        limit: number = 0,
        offset: number = 0
    ): Observable<any> {

        const requestData: any = {
            params: requestParams ? requestParams : {}
        };

        if (limit > 0) {
            requestData.limit = limit;
            requestData.offset = offset;
        }

        return this.httpClient.post(config.rootUrl + '/partner/requisite/get-props', requestData);
    }

}
